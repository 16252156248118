<template>
  <div class="PaypalCheckout">
    <div
      ref="container"
      :class="isInWebApp ? 'flex items-center justify-center border rounded' : ''"
      :style="isInWebApp ? 'height: 250px' : ''"
    >
      {{ isInWebApp ? 'Your Paypal Integration will appear here' : '' }}
    </div>

    <div v-if="localStatus === 'success'">Thank you for your payment!</div>

    <div class="flex justify-center m-1" v-if="isInWebApp">
      <a-button @click="localStatus = 'ready'">Set Status to Ready</a-button>
      <a-button @click="localStatus = 'success'">Set Status to Success</a-button>
    </div>
  </div>
</template>
<script>
/* global paypal */
// import api from '@/helpers/flowApi'
import conversionTrack from '@/components/form/helpers/conversionTrack'
import computedValues from './editor/helpers/computedValues'

export default {
  name: 'PaypalCheckout',
  inject: {
    _isInWebApp: { default: () => () => {} },
    _sendIndividualDataOutput: { default: () => () => {} },
    _flowType: { default: () => () => {} },
  },
  props: { component: Object, userData: Object, form: Object },
  data() {
    return {
      status: 'ready',
      errorMessage: '',
      localStatus: 'ready',
    }
  },
  computed: {
    isInWebApp() {
      return (this._isInWebApp() && this._flowType() !== 'wc') || false
      // return (this._isInWebApp() && !window.paypal) || false
    },
    name() {
      return (
        computedValues(this.userData, this.component.billing_info_name || '', this.form) ||
        undefined
      )
    },
    email() {
      return (
        computedValues(this.userData, this.component.billing_info_email || '', this.form) ||
        undefined
      )
    },
    phone() {
      return (
        computedValues(this.userData, this.component.billing_info_phone || '', this.form) ||
        undefined
      )
    },
    amount() {
      return (
        (typeof this.component.amount === 'number' &&
          (parseInt(this.component.amount) === this.component.amount
            ? parseInt(this.component.amount)
            : this.component.amount.toFixed(2))) ||
        0
      )
    },
  },
  mounted() {
    this.setUpPaypal()
  },
  beforeDestroy() {
    if (typeof this._cleanUpBridge === 'function') this._cleanUpBridge()
  },
  watch: {},
  methods: {
    async onSuccess() {
      this.localStatus = 'success'
      this.errorMessage = ''

      const successStatus = this.collectCardDetails ? 'card_added' : 'paid'
      this.$emit('update', [this.component.key, successStatus])

      if (this.component.next_on_complete) {
        await this.$nextTick()
        this.$emit('next')
      }

      const checkpoints = this.component.on_payment_complete_checkpoints
      if (Array.isArray(checkpoints) && checkpoints.length > 0) {
        this._setCheckpoint(checkpoints)
      }
      if (this.component.on_payment_complete_conversions) {
        conversionTrack(this.component, this.userData, this.form, 'on_payment_complete_conversions')
      }
      const outputs = this.component.on_payment_complete_outputs
      const now = new Date().toISOString()
      if (Array.isArray(outputs)) outputs.forEach(k => this._sendIndividualDataOutput(k, now))
    },
    async setUpBridgeElement() {
      if (typeof this._cleanUpBridge === 'function') this._cleanUpBridge()
      const bridge = document.createElement('div')
      bridge.id = `paypal-container-${this.component.id}`

      const containerEl = this.$refs.container.$el || this.$refs.container

      if (!this.isInWebApp) {
        bridge.slot = `paypal-${this.component.id}`
        document.querySelector(`savvy-flow[id="${this.form.id}"]`).appendChild(bridge)

        containerEl.innerHTML = ''
        const slot = document.createElement('slot')
        slot.name = bridge.slot
        containerEl.appendChild(slot)
      } else {
        containerEl.appendChild(bridge)
      }

      this._cleanUpBridge = () => {
        bridge.remove()
        containerEl.innerHTML = ''
        this._cleanUpBridge = null
        const el = document.querySelectorAll(`#${bridge.id}`)
        if (el.length) el.forEach(e => e.remove())
      }
      await this.$nextTick()
      return bridge.id
    },
    async setUpPaypal() {
      const mountId = await this.setUpBridgeElement()

      const payer = {}
      /* https://developer.paypal.com/api/orders/v2/#definition-payer */
      if (this.name) {
        const [given, last] = this.name.split(' ')
        payer.name = { given_name: given, surname: last }
      }
      if (this.email) {
        payer.email_address = this.email
      }

      const paypalConfigOptions = (this.component && this.component.buttons_config) || {}
      const style = {
        layout: paypalConfigOptions.layout || 'vertical',
        color: paypalConfigOptions.color || 'gold',
        shape: paypalConfigOptions.shape || 'rect',
        label: paypalConfigOptions.label || 'paypal',
        tagline: paypalConfigOptions.tagline || 'false',
      }
      if (paypalConfigOptions.size && paypalConfigOptions.size !== 'auto') {
        style.size = paypalConfigOptions.size
      }

      paypal
        .Buttons({
          style,
          // Sets up the transaction when a payment button is clicked
          createOrder:
            this.component.action === 'one-time-payment'
              ? (data, actions) => {
                  return actions.order.create({
                    purchase_units: [{ amount: { value: `${this.component.amount || 0}` } }],
                    payer: Object.keys(payer).length > 0 ? payer : undefined,
                  })
                }
              : undefined,
          createSubscription:
            this.component.action === 'subscription'
              ? (data, actions) => {
                  return actions.subscription.create({
                    plan_id: this.component.plan_id,
                    payer: Object.keys(payer).length > 0 ? payer : undefined,
                    // custom_id: (this.userData && this.userData.entryId.replace(/_/gi, '')) || undefined,
                  })
                }
              : undefined,

          // Finalize the transaction after payer approval
          onApprove: (data, actions) => {
            if (this.component.action === 'subscription') {
              if (
                this.component.output_subscription_id &&
                this.component.output_subscription_id_key
              )
                this.$emit('update', [
                  this.component.output_subscription_id_key,
                  data.subscriptionID,
                ])
              this.onSuccess()
            } else
              return actions.order.capture().then(() => {
                this.onSuccess()
                // Successful capture! For dev/demo purposes:
                // console.log('Capture result', orderData, JSON.stringify(orderData, null, 2))
                // var transaction = orderData.purchase_units[0].payments.captures[0]
                // alert(
                //   'Transaction ' +
                //     transaction.status +
                //     ': ' +
                //     transaction.id +
                //     '\n\nSee console for all available details'
                // )

                // When ready to go live, remove the alert and show a success message within this page. For example:
                // var element = document.getElementById('paypal-button-container');
                // element.innerHTML = '';
                // element.innerHTML = '<h3>Thank you for your payment!</h3>';
                // Or go to another URL:  actions.redirect('thank_you.html');
              })
          },
        })
        .render(`#${mountId}`)
    },
  },
}
</script>
